.main-container, .candidate-start-page-container{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

.candidate-start-page-container {
    width:75%;
    height:auto;
    box-sizing: border-box;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
}

.candidate-start-page-container .candidate,.candidate-start-page-container .base-candidate{
    font-family: 'Inter';
    font-style: normal;
}

.candidate-start-page-container .candidate{
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    margin-top:1em;
    margin-bottom:0.5em;
}
.candidate-start-page-container .base-candidate{
    font-weight: 400;
    font-size: 16px;
    line-height: 1.75;
}
@media only screen and (max-width: 600px) {
    .candidate-start-page-container{
        width:90%;
    }
  }

