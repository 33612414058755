.exam-questions-skeleton {
    height: 180px;
    width: 100%;
    background: #fff;
    margin-top: 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #6B7280;
    font-weight: 400;
    border: 1px solid #E5E7EB;
}
