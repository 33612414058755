.exams-list-view {}

.exams-list-view .exam-list-container {
    margin-top: 35px;
}

.exams-list-view .exam-list-container .list-header {
    height: 40px;
    background: #F9FAFB;
    border: 1.5px solid #E5E7EB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.exam-drive-result-details{
    padding-left: 5px;
    margin-top: 10px;
    color: #374151;
    font-size: 14px;
    font-weight: 400;
}

.exam-next-button{
    /*padding-left: 10px;*/
    margin-left: 10px;
    margin-top: 12px;
}
.exam-previous-button{
    margin-top: 12px;
}
.exam-next-button:hover{
    border: 1px solid #0369A1;
    border-radius: 6px;
}
.exam-previous-button:hover{
    border: 1px solid #0369A1;
    border-radius: 6px;
}

.exams-list-view .exam-list-container .list-header .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #6B7280;
}
.filter-fields .pi-search {
    font-size: 12px;
    font-weight: 600;
    margin-top: -5px;
}
.exams-list-view .filter-fields {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
}

.exams-list-view .exam-list-container .list-header .col {
    width: 20%;
}
.exams-list-view .exam-list-container .list-header .campus-drive-col {
    width: 25%;
}
.exams-list-view .exam-list-container .list-header .student-col {
    width: 15%;
}

.exams-list-view .exam-list-container .list-header .view-exam {
    width: 7%;
}

.exams-list-view .header {
    margin-top: 33px;
}

.exams-list-view .filter-fields .p-inputgroup .p-inputtext {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 0;
}

.exams .filter-fields .p-inputgroup .search .p-inputtext {
    border-right: 1px solid #E5E7EB;
}

.exams-list-view .filter-fields .p-inputgroup .p-inputtext::placeholder {
    font-size: 13px;
}


.exams-list-view .header .new-exam .new-exam-button.p-button {
    height: 44px;
    width: 220px;
    font-size: 14px;
    background: #0369A1;
    outline: none;
    border: none;
}

.exams-list-view .header .new-exam .new-exam-button.p-button:focus {
    outline: none;
    border: none;
}

.exams-list-view .header .title {
    font-size: 22px;
}

.exams-list-view .no-data {
    margin-top: 35px;
    color: #6B7280;
    font-size: 16px;
}

.no-exam-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    height: 240px;
}
.p-dropdown {
    border: none !important;
  }
