.candidate-termination-page-container, .candidate-termination-page-container .candidate-termination-content {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.candidate-termination-content{
    box-sizing: border-box;
    background-color: white;
    padding: 1.5em;
    border-radius: 10px;
}
.candidate-termination-page-container .candidate{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;

}
.candidate-termination-page-container .base-candidate{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #6B7280;
}
.err-msg{
    margin: 0.5em 1em;
}



