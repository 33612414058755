.examReviewPage-container .examReviewPage-container-form {
    width: 797px;
    background-color: #FFFFFF;
    margin-top: 43px;
    border: 1px solid #F9FAFB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    height: 798px;
}

.examReviewPage-container .examReviewPage-container-form h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1F2937;
    padding-left: 50px;
    padding-top: 36px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.examReviewPage-container .examReviewPage-container-form p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6B7280;
    font-feature-settings: 'salt' on;
    padding-left: 50px;
    margin-top: 7px;
}
.examReviewPage-container .examReviewPage-container-form .grid-container{
    display: grid;
    max-width: 698px;
    border-radius: 8px;
    margin-top: 27px;
    margin-bottom: 24px;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
    margin-left: 50px;
    border: 1px solid #E5E7EB;
    justify-content: center;
    text-align: center;
}
.examReviewPage-container .examReviewPage-container-form .grid-container .grid-item{
    border-bottom: 1px solid #E5E7EB;
    /*display: inline-flex;*/
    /*flex-direction: column;*/
    padding:6px 24.5px;
    justify-content:center;
    align-items: center;
}
.examReviewPage-container .examReviewPage-container-form .grid-container .grid-item .question-number{
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: block;
    justify-content: space-between;
    align-items: center;
    /*text-align: center;*/
}
.dot-circle-green{
    width: 7px;
    height: 7px;
    background: #10B981;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dot-circle-red{
    width: 7px;
    height: 7px;
    background: #E11D48;
    border-radius: 50%;
}
.dot-circle-green-display{
    width: 10px;
    height: 10px;
    background: #10B981;
    border-radius: 50%;
}
.dot-circle-red-display{
    width: 10px;
    height: 10px;
    background: #E11D48;
    border-radius: 50%;
}
.dot-explanation{
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
}
.answer{
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #6B7280;
}
.footer-content{
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    margin-bottom: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer-content .next-question-button{
    /*padding: 12px 24px;*/
    box-shadow: none !important;
    border-radius: 6px;
}
.footer-content .submit-exam-button{
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    color: #DC2626;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    text-align: center;
    font-feature-settings: 'salt' on;
    box-shadow: none !important;
}
