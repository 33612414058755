.markSheet-oeqt-container {
    margin-left: 3%;
    margin-right: 3%;
    border: solid 1px black;
}

.markSheet-oeqt-text {
    max-width: 100%;
    min-width: 100%;
    text-align: left;
    border-radius: 6px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    overflow: auto;
    scrollbarColor: white;
    height: 100%;
    max-height: 460px;
    min-height: 250px;
    color: black;
    padding-top: 20px;
    padding-left: 10px;
}