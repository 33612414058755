.add-questions-container {}

.add-questions-container .file-upload-container {
    margin-top: 25px;
}

.add-questions-container .file-upload-container .upload-button-container,
.add-questions-container .add-questions-form .add-button-container {
    margin-top: 15px;
}

.add-questions-container .add-questions-form .field {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
}

.add-questions-container .add-questions-form .no-margin-top {
    margin-top: 0;
}

.add-questions-container .add-questions-form .field.split {
    width: 48%;
}

.add-questions-container .add-questions-form .field .label,
.add-questions-container .add-questions-form .field .sub-label{
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.add-questions-container .add-questions-form .field.span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.add-questions-container .add-questions-form .field .point-field {
    height: 44px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #D1D5DB;
    margin-top: 7.5px;
}
.add-questions-container .add-questions-form .field .point-field .p-dropdown-label.p-placeholder{
    font-size:14px;
}

.add-questions-container .add-questions-form .field.span .input {
    margin-left: 10px;
}

.add-questions-container .add-questions-form .field .sub-label {
    margin-top: 5px;
}

.add-questions-container .add-questions-form .add-more-button {
    border: none;
    background: #fff;
    color: #00457B;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    padding-left: 0;
    cursor: pointer;
}

.add-questions-container .add-questions-form .field .input,
.add-questions-container .add-questions-form .field .p-multiselect,
.add-questions-container .add-questions-form .field .p-inputnumber {
    margin-top: 7.5px;
    width: 100%;
    height: 44px;
    font-size: 14px;
}

.add-questions-container .add-questions-form .field .p-inputnumber .input {
    font-size: 14px;
}

.add-questions-container .add-questions-form .field .input .p-dropdown-label {
    font-size: 14px;
}

.add-questions-form .field .input {
    font-size: 14px;
}

.error-validation-dialog{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align:center;
    padding:7px 0px 0px 7px;
    /*flex-wrap:wrap;*/
    /*height:calc(100vh - 100px);*/
}
.error-validation-row{
    padding:1px;
    margin-bottom: 2px;
}
/*.validation-dialog{*/
/*    padding-bottom: 10px;*/
/*}*/