.dashboard-view {
    height: 100%;
    width: 100%
}

.dashboard-layout {
    width: 1300px;
}

.dashboard-container {
    height: 100%;
    width: 100%;
}

.dashboard-layout .ongoing-exams,
.dashboard-layout .exam-status,
.dashboard-layout .upcoming-exam {
    margin-top: 30px;
    background: #fff;
    width: 100%;
    height: 240px;
    padding: 20px;
    border-radius: 8px;
}

.dashboard-layout .exam-status,
.dashboard-layout .upcoming-exam {
    width: 49%;
    height: 520px;
}

.dashboard-layout .ongoing-exams a,
.dashboard-layout .exam-status a,
.dashboard-layout .upcoming-exam a {
    text-decoration: none;
    color: #00457B;
    font-size: 14px;
    font-weight: 500;
}


.dashboard-layout .title {
    color: #111827;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-bottom: 1%;
    justify-content: space-between;
}

.dashboard-layout .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
    margin-top: 80px;
}

.dashboard-layout .upcoming-exam .content, .dashboard-layout .exam-status .content{
    margin-top: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

