.exam-card {
    /*//box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
    width: 100%;
    margin:2% 0;
    background: #F7FAFB;
    border-radius: 8px;
    border:1px solid #e0e0e0 ;/*#f6f6f8;*/
    cursor:pointer;
}

.dashboard-exam-list{
    padding:3%;
}

.dashboard-exam-list .title {
    color: #111827;
    font-size: 14px;
    font-weight: 500;
    padding-bottom:1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-exam-list .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
    font-weight: 400;
    color: #3e4149;
}

.exam-card .line-2 .university-name-col{
    width: 100%;
}

.exam-card .line-2 .exam-name-col{
    width:30%;
}

.exam-card .line-2 .participants-col{
    width:33%;
}

.exam-card .line-2 .percentage-passed-col{
    padding-left:3%;
    width:25%;
}

.exam-card .line-2 .date-col{
    width:20%;
}
.exam-card .arrow-icon{
    padding-top:2%;
    color: #6B7280;
}
