.evaluation-footer-flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px 10px;
    color: #3f3f46;
    font-family: Inter;
    font-weight: 400;
    font-feature-settings: 'salt' on;
}

.evaluation-timespent-container,.evaluation-points-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
}
.error-display {
    color: red;
    font-size: 12px;
    margin: 0;
}

.evaluation-next-container, .navigator-review-button{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.evaluation-next-button, .bookmark-button, .evaluation-back-button,.timespent-container{
    margin-top:7px;
}
.evaluation-point-display {
    margin-top: 10px;
}
.evaluated-points-container{
    margin: 0px 5px;
}
.eval-dialog-align h6{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #6B7280;
    padding-top: 0;
    margin-top: 10px;
    padding-bottom: 0;
}
.eval-exit-button-item{
    width: 121px !important;
    height: 44px !important;
}
