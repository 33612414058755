.list-questions-container {
    margin-top: 8px;
    margin-bottom: 20px;
}

.list-questions-container .header .title {
    font-size: 18px;
    font-weight: 500;
    color: #111827;
}

.list-questions-container .header .title .upload-status {
    margin-left: 10px;
    font-size: 12px;
    padding-top:5px;
}

.list-questions-container .list-question-header-button {
    margin-right:10px;
}

.list-questions-container .header .title .upload-status .info-icon {
    font-size: 11px;
    font-weight: 600;
    margin-right: 3px;
}

.list-questions-container .header .title .upload-status.success {
    color: #047857;
}

.list-questions-container .header .title .upload-status.error {
    color: #BE123C;
}

.list-questions-container .header .title .upload-status.started {
    color: #CA8A04;
}

.list-questions-container .list-questions-table {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    overflow: hidden;
    height: 291px;
    background: #fff;
}

.list-questions-container .row {
    height: 50px;
    display: grid;
    background: #fff;
    border-bottom: 1px solid #E5E7EB;
    grid-template-rows: 1fr;
    grid-template-columns: 2% 715px 14.5% 15% 12.5%;
    grid-template-areas: "edit question exam category more-info";
}

.list-questions-container .row .cell.edit {
    grid-area: edit;
    font-weight: 400;
    color: #4B5563;
    margin-left: 15px;
}

.list-questions-container .row.row-header {
    height: 40px;
    background: #F9FAFB;
}

.list-questions-container .row .cell.question {
    grid-area: question;
    width: 715px;
    height: 50px;
    padding: 0 20px;
    font-weight: 500;
    color: #111827;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-questions-container .row .cell.question .text-ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-questions-container .row .cell.exam {
    grid-area: exam;
    font-weight: 400;
    color: #4B5563;
    padding-left: 15px;
}

.list-questions-container .row .cell.category {
    grid-area: category;
    font-weight: 400;
    color: #4B5563;
}

.list-questions-container .row .cell.more-info {
    grid-area: more-info;
    font-weight: 400;
    color: #0284C7;
    justify-content: flex-end;
    padding-right: 20px;
}

.list-questions-container .row .cell {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
}

.list-questions-container .row.row-header .cell {
    height: 40px;
    color: #6B7280;
    font-size: 12px;
    font-weight: 500;
}

.list-questions-container .footer {
    margin-top: 10px;
}

.list-questions-container .footer .result-details {
    color: #374151;
    font-size: 14px;
    font-weight: 400;
}

.list-questions-container .footer .pagination-buttons .qns-previous-button {
    margin-right: 15px;
}

.list-questions-container .footer .pagination-buttons .qns-next-button {
    margin-right: 0;
}

.p-dialog.question-dialog .p-dialog-header .p-dialog-title {
    font-size: 16px;
    color: #111827;
}

.filter-container .download-p-icon-right{
    width:30px;
    background: #FFFFFF;
    border-left: 1px solid #E5E7EB;
}

.list-questions-container .filter-container .p-dropdown{
    background: none;
    border:none;
}