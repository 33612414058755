.list-evaluator-container {
    margin-top: 8px;
    margin-bottom: 20px;
}

.list-evaluator-container .header .title {
    font-size: 18px;
    font-weight: 500;
    color: #111827;
}

.list-evaluator-container .header .title .upload-status {
    margin-left: 10px;
    font-size: 12px;
    padding-top:5px;
}

.list-evaluator-container .header .title .upload-status .info-icon {
    font-size: 11px;
    font-weight: 600;
    margin-right: 3px;
}

.list-evaluator-container .header .title .upload-status.success {
    color: #047857;
}

.list-evaluator-container .header .title .upload-status.error {
    color: #BE123C;
}

.list-evaluator-container .header .title .upload-status.started {
    color: #CA8A04;
}

.list-evaluator-container .list-evaluator-table {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    overflow: hidden;
    height: 291px;
    background: #fff;
}

.list-evaluator-container .row {
    height: 50px;
    display: grid;
    background: #fff;
    border-bottom: 1px solid #E5E7EB;
    grid-template-rows: 1fr;
    grid-template-columns: 30% 25% 25% 20%;
    grid-template-areas: "name reg-no dob gender";
}

.list-evaluator-container .row.row-header {
    height: 40px;
    background: #F9FAFB;
}

.list-evaluator-container .row .cell.name {
    grid-area: name;
    width: 715px;
    height: 50px;
    padding: 0 20px;
    font-weight: 500;
    color: #111827;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-evaluator-container .row .cell.name .text-ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-evaluator-container .row .cell.reg-no {
    grid-area: reg-no;
    font-weight: 400;
    color: #4B5563;
    padding-left: 15px;
}

.list-evaluator-container .row .cell.dob {
    grid-area: dob;
    font-weight: 400;
    color: #4B5563;
}

.list-evaluator-container .row .cell.gender {
    grid-area: gender;
    font-weight: 400;
    color: #4B5563;
    padding-right: 20px;
}

.list-evaluator-container .row .cell {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
}

.list-evaluator-container .row.row-header .cell {
    height: 40px;
    color: #6B7280;
    font-size: 12px;
    font-weight: 500;
}

.list-evaluator-container .footer {
    margin-top: 10px;
}

.list-evaluator-container .footer .result-details {
    color: #374151;
    font-size: 14px;
    font-weight: 400;
}

.list-evaluator-container .footer .pagination-buttons .previous-button {
    margin-right: 15px;
}

.list-evaluator-container .footer .pagination-buttons .next-button {
    margin-right: 0;
}

.list-evaluator-container .filter-fields .p-inputgroup .p-inputtext {
    width: 250px!important;
}

.list-evaluator-line {
    width: 100%;
    height: 1px;
    background: #E5E7EB;
    margin-top: 40px;
    margin-bottom: 40px;
}
