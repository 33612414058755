.evaluate-exam-container {
    width: 518px;
    background-color: white;
    margin-top: 70px;
    padding: 30px 30px 30px 30px;
    border: 1px solid #F9FAFB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

.evaluate-exam-container .evaluate-exam-form {
    margin-top: 15px;
}

.evaluate-exam-container .evaluate-exam-form h2{
    color: #1F2937;
    font-weight: 600;
    font-family: Inter;
    line-height: 32px;
    font-size: 24px;
}

.evaluate-exam-container .evaluate-exam-form h5{
    color: #6B7280;
    font-weight: 400;
    font-family: Inter;
    line-height: 24px;
    font-size: 16px;
    font-style: normal;
    font-feature-settings: 'salt' on;
    width: 434px;
    height: 72px;
}
.evaluate-exam-container .evaluate-exam-form .field .entire-field{
    margin-bottom: 25px;
}
.evaluate-exam-container .evaluate-exam-form .field .entire-field .error-label{
    margin: 5px;
    width:100%;
}

.evaluate-exam-container .evaluate-exam-form .label{
    color: #4B5563;
    font-weight: 500;
    font-family: Inter;
    line-height: 20px;
    font-size: 14px;
    font-style: normal;
    padding-bottom: 5px;
}

.evaluate-exam-container .evaluate-exam-form .field{
    margin-top: 25px;
    margin-bottom: 0;
}
/*.evaluate-exam-container .evaluate-exam-form .input{*/
/*    color: #6B7280;*/
/*    font-weight: 400;*/
/*    font-family: Inter;*/
/*    line-height: 24px;*/
/*    font-size: 14px;*/
/*    font-style: normal;*/
/*}*/

.evaluate-exam-container .evaluate-exam-form .field{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
}

.evaluate-exam-container .evaluate-exam-form .no-margin-top{
    margin-top: 0;
}
.evaluate-exam-container .evaluate-exam-form .field .split{
    width: 48%;
}

.evaluate-exam-container .evaluate-exam-form .field .label{
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.evaluate-exam-container .evaluate-exam-form  .field .entire-field .input{
    border: 1px solid #D1D5DB;
    box-sizing: border-box;
    width: 434px;
    height: 42px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: blue;
    font-weight: 100;
    font-family: Inter;
    font-size: 5px;
}

.upload-button-container .upload-button{
    padding: 12px 24px;
}
.upload-button-item {
    box-shadow: none !important;
}
.evaluate-exam-container .evaluate-exam-form  .field .entire-field .point-field {
    height: 42px;
    width: 434px;
    border-radius: 6px;
    border: 1px solid #D1D5DB;
    padding-left: 12.5px;
    font-size: 16px;
    font-weight: 100;
    font-family: Inter;
    margin-top: 7.5px;
}

.review-container .exam-list-container {
    margin-top: 35px;

}
.review-container .skeleton{
    width: 100%;
    align-self: center;
}

.review-container .exam-list-container .list-header {
    width: 100%;
    height: 40px;
    background: #F9FAFB;
    border: 1.5px solid #E5E7EB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.review-container .exam-list-container .list-header .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #6B7280;
}
.column-container .review-container{
    border-radius: 8px;
}

.no-exam-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    height: 240px;
}
