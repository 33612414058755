.list-exam-row {
    height: 50px;
    width: 80%;
    background: #F9FAFB;
    border-left: 1.5px solid #E5E7EB;
    border-right: 1.5px solid #E5E7EB;
}

.list-exam-row.shade {
    background: #FFFFFF;
}

.list-exam-row.last-item {
    border-bottom: 1.5px solid #E5E7EB;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.list-exam-row .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
}

.list-exam-row .campus-drive-col {
    width: 25%;
}

.list-exam-row .exam-name-col {
    color: #111827;
    font-weight: 500;
    width: 20%;
    display: flex;
    flex-direction: row;
}

.list-exam-row .col {
    width: 20%;
    display: flex;
    flex-direction: row;
}

.list-exam-row .student-col{
    width:15%
}

.list-exam-row .buttons{
    width :15%;
    justify-content: center;
}


.list-exam-row .view-exam {
    padding: 10px;
    width: auto;
}


.list-exam-row .view-exam-link a{
    color: #1E3A8A;
}

.list-exam-row .view-exam a {
    color: #1E3A8A;
    font-size: 14px;
    font-weight: 400;
}

.view-exam .description {
    color: coral;
}

/* .view-eval-rev-container{
    visibility: hidden;
}

.list-exam-row:hover .view-eval-rev-container {
        visibility: visible;
} */