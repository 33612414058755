.create-campus-drive-view {
    width: 350px;
}

.create-campus-drive-view .create-drive-form {}

.create-campus-drive-view .field {
    width: 100%;
    margin-top: 35px;
}

.create-campus-drive-view .field .error-label {
    margin-left: 12px;
}

.create-campus-drive-view .field .label {
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.create-campus-drive-view .field .input {
    margin-top: 7.5px;
    width: 100%;
    height: 42px;
    font-size: 14px;
}

.create-campus-drive-view .drive-duration .p-inputtext {
    font-size: 14px;
    color: #4B5563;
}

.create-campus-drive-view .university .input .p-dropdown-label {
    font-size: 14px;
}

.create-campus-drive-view .submit-button-container {
    margin-top: 35px;
}

.create-campus-drive-view .submit-button.p-button {
    background: #0369A1;
    width: 94px;
    height: 44px;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
}

.create-exam-view .submit-button.icon-present.p-button {
    width: 170px;
}

.create-drive-form .p-button-label {
    margin-left: -7px;
}

.create-campus-drive-view .submit-button.p-button:hover {
    background: #0369A1;
}

.create-campus-drive-view .header {
    margin-top: 33px;
}

.create-campus-drive-view .header .back-navigation {}

.create-campus-drive-view .header .back-navigation .label {
    font-size: 14px;
    color: #475569;
    margin-left: 2.5px;
}

.create-campus-drive-view .header .back-navigation .pi-angle-left {
    font-size: 14px;
    color: #64748B;
}

.create-campus-drive-view .header .title {
    margin-top: 5px;
    font-size: 22px;
    font-weight: 700;
}

.p-datepicker {
    transform: scale(0.9)!important;
    left: 39.5%!important;
}
.plus-button {
    width: 10px;
    height: 10px;
    color: #FFFFFF;
    border: 1px solid #0369A1;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 5px;
    padding-left: 4px;
    border-radius: 4px;
    background-color: #0369A1;
}
