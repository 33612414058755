@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #0369A1;
    }
    40% {
        stroke: #0369A1;
    }
    66% {
        stroke: #0369A1;
    }
    80%,
    90% {
        stroke: #0369A1;
    }
}

.question-navigator-display {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: start;
}

.question-navigator-display-item{
    flex: 1 1 20%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.number {
    display: table-cell;
    vertical-align: middle;
}

/*navigator styles*/
.question-navigator-question-number {
    height: 50px;
    width: 50px;
    font-size: 20px;
    display: table;
    cursor: pointer;
}

.question-navigator-question-number-default{
    color: grey;
}

.question-navigator-question-number-selected{
    background-color: #0369A1;
    border-radius: 50%;
    color: white;
}

.question-navigator-question-number-marked {
    background-color: darkorange;
    /*border: 1px solid darkorange;*/
    border-radius: 50%;
    /*color: darkorange;*/
    color: white;
}

.question-navigator-question-number-answered {
    /*background-color: olive;*/
    border: 1px solid green;
    border-radius: 50%;
    color: green;
}

.question-navigator-question-number-skip {
    /*background-color: olive;*/
    border: 1px solid red;
    border-radius: 50%;
    color: red;
}
