.review-page-container{
    width:100%;
    display: flex;
    flex-direction: column;
}

.review-page-container .exam-list-container ,.review-page-container .header,.review-page-container .skeleton{
    width:80%;    
    overflow-y: scroll;
    align-self: center;
    margin:15px 0px;
}

.review-page-container .exam-list-container .content{
    height: 100%;
    position: relative;
}

.review-page-container .exam-list-container .list-header {
    height: 40px;
    background: #F9FAFB;
    border: 1.5px solid #E5E7EB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.review-page-container .header .title {
    font-size: 22px;
}


.review-page-container .exam-list-container .list-header .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #6B7280;
}
.review-page-container .no-data {
    margin-top: 35px;
    color: #fe0a0a;
    font-size: 16px;
}


.no-data .no-exam-content {
    width: 80%;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    height: 240px;
}