.view-question-modal {
    width: 100%;
    height: auto;
}

.view-question-modal .options .options-title,
.view-question-modal .answer .answer-title {
    color: #9CA3AF;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 2px;
}

.view-question-modal .answer .answer-title {
    margin-top: 25px;
}

.view-question-modal .options .options-value,
.view-question-modal .answer .answer-value{
    color: #1F2937;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

/*.view-question-modal .answer .answer-value{*/
/*    margin-bottom: 0;*/
/*}*/
