.candidate-exam-view, .candidate-exam-view .section-2{
    display:flex;
    flex-direction:column;
}

.candidate-exam-view .section-1{
    min-height: 70px;
    height:70px;
    margin-bottom:10px;
}

.candidate-exam-view .section-2{
    /*justify-content: center;*/
    align-items: center;
    flex-grow: 1;
    margin-top:150px;
}

.malpractice-width{
    width: 35%;
}

.malpractice-width{

}


/*.malpractice-main{*/
/*    margin-top: 5%;*/
/*}*/

.dialog-message{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #6B7280;
    margin-left:13px;
    padding:2px;
    line-height:30px;
}

.malpractice-button{
    width:80px;
    padding:0px;
    float: right;
}

.malpractice-width .p-dialog-title{

}

.malpractice-button Button{
    width: 100%;
}

.p-dialog-title{
    width: 100%;
}
.malpractice-width .warninglogo{
   margin:0px !important;
}
.malpractice-Header{
    margin-left:.5rem;
    margin-top:.4em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #111827;
}

.dialog-pop .add-button-container{
    margin-top: 15px;
    display:flex;
    justify-content: center;
    margin-left:120px;
    width:300px;
}

.dialog-pop .dialog-content{
    text-align: left;
}
 .end-dialog-row-container{
    display: flex;
     align-content: center;
     justify-content: start;

}
 .end-exam-dialog-align{
     padding:0px;
     margin:0px 10px;
 }
 .end-dialog-content-message{
     font-family: 'Inter';
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 1.5;
     color: #6B7280;
 }
 .time-alert{
     display:block;
     width:98%;
     margin:0px 20px;
     border: 1px solid #F9FAFB;
     box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
     border-radius: 8px;
     /*background-color: #c0d8f8;*/
     background-color: #FFFFFF;
     padding:0 20px;
 }
.candidate-exam-view .card .grid {
    height:60px;
    margin:5px;
    border-left: 5px solid #0369A1;
}
.icon-clock{
    width:4% !important;
    padding: 5px 0px 5px 10px !important;
}
.candidate-exam-view .card .grid .md\:col-1 {
   width:3%;
}
.candidate-exam-view .card .grid .md\:col-10{
    width:93%;
}
 .icon-clock .pi{
     font-size:40px !important;
     color: #ffd464;
     padding: 5px;
 }

 .icon-close .pi{
     font-size:12px !important;
     color:#6B7280;
     font-weight: bolder;
 }

.time-alert .info, .time-alert .sub-info{
    padding:0px 5px 8px 5px;
    margin:0;
}
.time-alert .info{
    font-weight: bold;
    color: #0369A1;
}
.time-alert .sub-info{
    color: #6B7280;
    font-size:15px;
}

