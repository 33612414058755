.candidate-end-page-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}

.candidate-end-page-content{
    box-sizing: border-box;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
}

.candidate-end-page-container .candidate-end{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
}
.candidate-end-page-container .base-candidate-end{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
}

.candidate-end-page-container .candidate-end ,.candidate-end-page-container .base-candidate-end{
    margin: 1.5em;
}

.candidate-end-page-container .base-candidate-end-2{
    font-family: 'Inter';
    height: 189px;
    width: 699px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-top:2% ;
}


.candidate-end-page-container .candidate-start-form {
    margin-top: 15px;
}

.candidate-end-page-container .candidate-start-form h5{
    color: #4B5563;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.candidate-end-page-container .candidate-start-form .field{
    width: 100%;
    margin-top: 15%;
    margin-bottom: 0;
}

.candidate-end-page-container .candidate-start-form .no-margin-top{
    margin-top: 0;
}

.candidate-end-page-container .candidate-start-form .field .label{
    color: #4B5563;
    width: 77px;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
}



.start-exam-button{
    margin-top:30px;
    margin-left: 41%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 18%;


}
.cont{
    margin-top: 5%;
}
.field-width{
    width: 434px;
}

.context{
    top:133px;left: 49px;
    right:49px;
    width:699px;
    height:189px;
}

ul{
    padding-left: 5%;
    margin-right: 8%;
    color: #6B7280;
    font-weight: 400;
}

.succeslogo{
    margin-top:10px;
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    background: #D1FAE5;
    border-radius: 24px;
}
.iconposition
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ticklogo{
    width: 20px;
    height: 20px;

}
