.question-display-container{
    height:100%;
    width:50%;
}

@media only screen and (min-width: 800px) and (max-width: 900px){
    .question-display-container{
        width:60%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 800px){
    .question-display-container{
        width:70%;
    }
}
@media only screen and (max-width: 600px){
    .question-display-container{
        width:90%;
    }
}