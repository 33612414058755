.list-candidates-container {
    margin-top: 8px;
    margin-bottom: 20px;
}

.list-candidates-container .header .title {
    font-size: 18px;
    font-weight: 500;
    color: #111827;
}

.list-candidates-container .header .title .upload-status {
    margin-left: 10px;
    font-size: 12px;
    padding-top:5px;
}
.list-candidate-header-btn{
    margin-right:10px
}

.list-candidates-container .header .title .upload-status .info-icon {
    font-size: 11px;
    font-weight: 600;
    margin-right: 3px;
}

.list-candidates-container .header .title .upload-status.success {
    color: #047857;
}

.list-candidates-container .header .title .upload-status.error {
    color: #BE123C;
}

.list-candidates-container .header .title .upload-status.started {
    color: #CA8A04;
}

.list-candidates-container .list-candidates-table {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    overflow: hidden;
    height: 291px;
    background: #fff;
}

.list-candidates-container .row {
    height: 50px;
    display: grid;
    background: #fff;
    border-bottom: 1px solid #E5E7EB;
    grid-template-rows: 1fr;
    grid-template-columns: 2% 19% 19% 19% 19% 19%;
    grid-template-areas: "edit name reg-no dob gender invite review";
}

.list-candidates-container .row.row-header {
    height: 40px;
    background: #F9FAFB;
}

.list-candidates-container .row .cell.name {
    grid-area: name;
    width: 715px;
    height: 50px;
    padding: 0 20px;
    font-weight: 500;
    color: #111827;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-candidates-container .row .cell.name .text-ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-candidates-container .row .cell.reg-no {
    grid-area: reg-no;
    font-weight: 400;
    color: #4B5563;
    padding-left: 15px;
}

.list-candidates-container .row .cell.dob {
    grid-area: dob;
    font-weight: 400;
    color: #4B5563;
}

.list-candidates-container .row .cell.gender {
    grid-area: gender;
    font-weight: 400;
    color: #4B5563;
    padding-right: 20px;
}

.list-candidates-container .row .cell.invite {
    grid-area: invite;
    font-weight: 400;
    color: #4B5563;
    padding-right: 5px;
}

.list-candidates-container .row .cell.review {
    grid-area: review;
    font-weight: 400;
    color: #4B5563;
    padding-right: 5px;
}


.list-candidates-container .row .cell.edit {
    grid-area: edit;
    font-weight: 400;
    color: #4B5563;
    /*padding-right: 5px;*/
    /*padding-left: 6px;*/
    margin-left: 15px;
}

.list-candidates-container .row .cell {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
}

.list-candidates-container .row.row-header .cell {
    height: 40px;
    color: #6B7280;
    font-size: 12px;
    font-weight: 500;
}

.list-candidates-container .footer {
    margin-top: 10px;
}

.list-candidates-container .footer .result-details {
    color: #374151;
    font-size: 14px;
    font-weight: 400;
}

.list-candidates-container .footer .pagination-buttons .previous-button {
    margin-right: 15px;
}

.list-candidates-container .footer .pagination-buttons .next-button {
    margin-right: 0;
}

.list-candidates-container .filter-fields .p-inputgroup .p-inputtext {
    width: 250px!important;
}

.list-candidate-line {
    width: 100%;
    height: 1px;
    background: #E5E7EB;
    margin-top: 40px;
    margin-bottom: 40px;
}

.drowdown-outline{
    width: 200px;
}

.dialog-align{
    padding: 10px;
}

.candidate-invite-dialog{
    padding:0px !important;
    padding-top:20px !important;
}

.invite-footer-button{
    padding-top:0.5rem;
}

.email-label{
    padding-bottom: 10px;
}

.email-field-input InputText{
    width: 300px;
}

.input-text{
    width: 400px;
}

.endParticular--button{
    padding-top: 5px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.endParticular--button Button{
    margin-right: 15px;
}

.list-candidates-container .p-progressbar .p-progressbar-value{
    background:#0369A1 !important;
    width:50%;
}
.list-candidates-previous-button .spin-check-button.p-button {
    margin-left: -10px !important;
}

.list-candidates-next-button .spin-check-button.p-button {
    margin-left: 4px !important;
}
