.spin-check-button.p-button {
    height: 100%;
    width: 100%;
}

.spin-check-button.p-button{
    font-size: 14px;
    background: #0369A1;
    outline: none;
    border: none;
    border-radius: 6px;
}
.spin-check-button .p-button-label{
    margin-left: -10px;
    margin-right: 4px;
}

.spin-check-button.p-button:enabled:hover{
    background: #075985 !important;
    color: #FFFFFF !important;
    border: none;/*#075985 !important;*/
}


.MarkForReview-button.p-button,
.MarkForReview-button.p-button:enabled:hover{
    font-size: 14px;
    background: #E0F2FE;
    color:#0369A1;
    border-radius: 6px;
    outline: none;
    border: none;
}

.Un-MarkForReview-button.p-button,
.Un-MarkForReview-button.p-button:enabled:hover{
    font-size: 14px;
    background: #FFFFFF;
    color:#DC2626;
    border-radius: 6px;
    border: 1px solid lightgrey;
    outline: none;
}

.dialog-submitQuestions-button.p-button,
.dialog-submitQuestions-button.p-button:enabled:hover{
    font-size: 14px;
    color:#FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* sky/800 */
    background: #075985;
    /* shadow/sm */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);


}

.submitQuestions-button.p-button,
.submitQuestions-button.p-button:enabled:hover{
    font-size: 14px;
    color:#FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    width: 222px;
    height: 38px;
    /* sky/800 */

    background: #075985;
    /* shadow/sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);


}

.submitQuestion-button.p-button,
.submitQuestion-button.p-button:enabled:hover{
    font-size: 14px;
    color:#FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 40px;
    background: #0369A1;
    line-height: 20px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);


}

.dialog-cancel-button.p-button,
.dialog-cancel-button.p-button:enabled:hover{
    font-size: 14px;
    background: #FFFFFF;
    color:#374151;
    border-radius: 6px;
    outline: none;
    border: 1px solid #D1D5DB;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 81px;
    height: 38px;
    margin-right:12px;
}

.cancel-button.p-button,
.cancel-button.p-button:enabled:hover{
    font-size: 14px;
    background: #FFFFFF;
    color:#374151;
    border-radius: 6px;
    outline: none;
    border: 1px solid #D1D5DB;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 81px;
    height: 38px;
    margin-right:12px;
}

.spin-check-button .p-button-icon{
    margin-right:1em !important;
}

