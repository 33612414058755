.candidateReviewPage-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.candidateReviewPage-container .candidateReviewPage-container-form {
    display:flex;
    flex-direction: column;
    width: 60%;
    background-color: #FFFFFF;
    border: 1px solid #F9FAFB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

.candidateReviewPage-container .candidateReviewPage-container-form .review-header{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1F2937;
    margin:0em 1em;
    margin-top:2em;
    padding:0em 1em;
}
.candidateReviewPage-container .candidateReviewPage-container-form .review-content{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6B7280;
    margin:1em;
    padding:0em 1em;
    font-feature-settings: 'salt' on;
}
.candidateReviewPage-container .candidateReviewPage-container-form .grid-container{
    display: grid;
    width:80%;
    border-radius: 8px;
    margin:1em 0em;
    /*padding:1em 0em;*/
    align-self:center;
    grid-template-columns: repeat(auto-fill, 75px);
    border: 1px solid #E5E7EB;
    justify-content: center;
    text-align: center;
}
.candidateReviewPage-container .candidateReviewPage-container-form .grid-container .grid-item{
    /*border-bottom: 1px solid #E5E7EB;*/
    /*display: inline-flex;*/
    /*flex-direction: column;*/
    margin: 0.5em 0em;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    border-right: 0.5px solid #E5E7EB;
    border-left: 0.5px solid #E5E7EB;
    cursor: pointer;
    /*border-radius: 8px;*/
}
.candidateReviewPage-container .candidateReviewPage-container-form .grid-container .grid-item .question-number{
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: block;
    justify-content: space-between;
    align-items: center;
    /*text-align: center;*/
}

.dot-circle{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dot-circle-green{
    background: #10B981;
}
.dot-circle-red{
    background: #E11D48;
}
.dot-circle-yellow{
    background: darkorange;
}
.dot-circle-gray{
    background: #D1D5DB;
}
.dot-circle-green-display{
    width: 10px;
    height: 10px;
    background: #10B981;
    border-radius: 50%;
}
.dot-circle-gray-display{
    width: 10px;
    height: 10px;
    background: #D1D5DB;
    border-radius: 50%;
}
.dot-circle-yellow-display{
    width: 10px;
    height: 10px;
    background: darkorange;
    border-radius: 50%;
}

.dot-explanation{
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2px;
}
.answer{
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #6B7280;
}
.footer-co{
    margin:2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footer-co .next-question-button{
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}
.footer-co .submit-evaluation-button{
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    color: #DC2626;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    text-align: center;
    font-feature-settings: 'salt' on;
    box-shadow: none!important;
}
.footer-co .submit-evaluation-button:enabled:hover{
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    color: #DC2626;
}
.dialog-pop{
    width:50%;
}
.dialog-column-container{
    display:flex;
    flex-direction:column;
}
.dialog-row-container{
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
}
.warninglogo{
    width: 40px;
    height: 40px;
    background: #F0F9FF;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.warninglogo, .textHeader{

    margin:0.5em;
}

.warlogo
{
    color:#075985;
    width: 28px;
    height: 20px;
}

.textHeader
{
    margin-top:1em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #111827;
    text-align: center;

}
.dialog-content-message{
    width:80%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #6B7280;
}
.cancel-button
{
    margin-right: 8px;
}
.add-button-container{
    display: flex;
   flex-direction: row;

}
.p-dialog .p-dialog-header, .p-dialog .p-dialog-header .p-dialog-title
{
    display: flex;
    padding-bottom: 0px!important;
}
.dialog-align {
    padding-bottom: 0px!important;
    align-items: center;
    justify-content: center;
}
@media only screen and (min-width: 600px) and (max-width: 850px){
    .dialog-pop{
        width:70%;
    }
}

@media only screen and (max-width: 600px){
    .dialog-pop{
        width:90%;
    }
}
