.candidate-start-error-page{
    width:70%;
}
.candidate-start-error-page-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: white;
    padding: 2.5em 2em;
    border-radius: 10px;
    width:100%;
}

.candidate-start-error-page-container .candidate-error-header{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-top:1em
}
.candidate-start-error-page-container .candidate-error-content{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #6B7280;
    margin-bottom: 1em;
    line-height: 1.5;
}
.candidate-start-error-page-container .candidate-error-header,.candidate-start-error-page-container .candidate-error-content{
    margin:8px 16px;
}
@media only screen and (min-width: 600px) and (max-width:900px) {
    .candidate-start-error-page{
        width:80%;
    }
  }

@media only screen and (max-width: 600px) {
    .candidate-start-error-page{
        width:90%;
    }
  }


