.university-skeleton {
    width: 100%;
    margin-top: 35px;
}

.university-skeleton .label {
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.university-skeleton .input {
    margin-top: 7.5px;
    width: 100% !important;
    height: 42px !important;
    font-size: 14px;
}
