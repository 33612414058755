.view-exams-view .header {
    margin-top: 33px;
}

.view-exams-view .header .edit-exam{
    height: 44px;
    width: 120px;
    margin: 5px;
}

.view-exams-view .header .evaluation-filter,
.view-exams-view .header .evaluate-exam-button{
    height: 44px;
    width: auto;
    margin: 5px;
    position: relative;
    display: inline-block;
}

.view-exams-view .header .evaluate-exam-button .p-button{
    background: #047857 !important;
    border-radius: 8px;
}

.view-exams-view .header .end-exam{
    height: 44px;
    width: 120px;
    margin : 5px;

}

.view-exams-view .header .end-exam .start-exam{
    background: #047857;
}

.view-exams-view .header .end-exam .start-exam:hover{
    background-color: #026247 !important;
    color: #FFFFFF !important;
    transition: 0.3s !important;
}

.view-exams-view .header .end-exam .end-exam-button:hover{
    background-color: #ec3333 !important;
    color: #FFFFFF !important;
    transition: 0.3s !important;
}

.end-exam, .cancel{
    height: 44px;
    width: 120px;
    margin : 10px;

}

.view-exams-view .header .evaluate-exam{
    height: 44px;
    width: 120px;
    margin : 5px;
}

.view-exams-view .header .exam-status-container {
    height: 44px;
}


.view-exams-view .header .exam-status-container .exam-status .upcoming,
.view-exams-view .header .exam-status-container .exam-status .completed,
.view-exams-view .header .exam-status-container .exam-status .ongoing {
    border-radius: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 9px;
}

.view-exams-view .header .exam-status-container .exam-status .upcoming {
    height: 20px;
    width: 80px;
    background: #E0F2FE;
}

.view-exams-view .header .exam-status-container .exam-status .completed {
    height: 20px;
    width: 87px;
    background: #D1FAE5;
}

.view-exams-view .header .exam-status-container .exam-status .ongoing {
    height: 20px;
    width: 75px;
    background: #FEF9C3;
}

.view-exams-view .header .exam-status-container .exam-status .upcoming .status-dot,
.view-exams-view .header .exam-status-container .exam-status .completed .status-dot,
.view-exams-view .header .exam-status-container .exam-status .ongoing .status-dot{
    height: 5px;
    width: 5px;
    border-radius: 50%;
}

.view-exams-view .header .exam-status-container .exam-status .upcoming .status-dot{
    background: #0369A1;
}
.view-exams-view .header .exam-status-container .exam-status .completed .status-dot{
    background: #047857;
}
.view-exams-view .header .exam-status-container .exam-status .ongoing .status-dot{
    background: #CA8A04;
}

.view-exams-view .header .exam-status-container .exam-status .upcoming .status-label,
.view-exams-view .header .exam-status-container .exam-status .completed .status-label,
.view-exams-view .header .exam-status-container .exam-status .ongoing .status-label {
    font-size: 9px;
    font-weight: 700;
}

.view-exams-view .header .exam-status-container .exam-status .upcoming .status-label {
    color: #0369A1;
}
.view-exams-view .header .exam-status-container .exam-status .completed .status-label {
    color: #047857;
}
.view-exams-view .header .exam-status-container .exam-status .ongoing .status-label {
    color: #CA8A04;
}

.view-exams-view .header .title {
    font-size: 22px;
}

.view-exams-view .title-container .back-navigation .label {
    font-size: 14px;
    color: #475569;
    margin-left: 2.5px;
}

.view-exams-view .title-container .back-navigation .pi-angle-left {
    font-size: 14px;
    color: #64748B;
}

.view-exams-view .title-container .title {
    margin-top: 5px;
    font-size: 22px;
    font-weight: 700;
}

.view-exams-view .title-container .sub-title {
    font-size: 12px;
    color: #475569;
    padding-top: 7px;
    font-weight: 600;
    letter-spacing: 0.05em;
}
.view-exams-view .title-container .warning-text{
    color:indianred !important;
}

.view-exams-view .title-container .success-text{
    color:#047857 !important;
}

.view-exams-view .header-card-container {
    margin-top: 45px;
}

.view-exams-view .candidates-list-container,
.view-exams-view .questions-list-container {
    margin-top: 50px;
}

.view-exams-view .candidates-list-container .header,
.view-exams-view .questions-list-container .header {
    margin-top: 0;
}

.view-exams-view .candidates-list-container .header .title,
.view-exams-view .questions-list-container .header .title {
    font-size: 18px;
    font-weight: 500;
    color: #111827;
}

.view-exams-view .candidates-list-container .header .add-candidates{
    height: 44px;
    width: 150px;
}


.view-exams-view .questions-list-container .header .add-questions {
    height: 44px;
    width: 150px;
}
.view-exams-view .filter-fields {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
}

.view-exams-view .filter-fields .p-inputgroup .p-inputtext {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 0;
}

.view-exams-view .filter-fields .p-inputgroup .search .p-inputtext {
    border-right: 1px solid #E5E7EB;
}

.view-exams-view .filter-fields .p-inputgroup .p-inputtext::placeholder {
    font-size: 13px;
}

.filter-item {
    color: #6B7280;
    font-size: 11px;
    font-weight: 700;
}

.filter-fields .pi-search {
    font-size: 12px;
    font-weight: 600;
    margin-top: -5px;
}

.view-exams-view .filter-icon {
    margin-left: 15px;
}

.list-candidates-container .p-dropdown:not(.p-disabled).p-focus,
.list-questions-container .p-dropdown:not(.p-disabled).p-focus {
    outline: none!important;
    outline-offset: 0!important;
    box-shadow: none!important;
    border: none!important;
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: none!important;
    outline-offset: 0!important;
    box-shadow: none!important;
    /* border: 1px solid #D1D5DB!important; */
}

.view-exams-view .p-dropdown .p-dropdown-trigger {
    width: 30px;
    margin-right: 5px;
}

.view-exams-view .p-dropdown {
    width: 120px!important;

}

.view-exams-view .filter-fields .p-inputgroup .p-inputtext.p-dropdown-label {
    width: 100px;
    color: #374151;
    font-size: 14px;
    font-weight: 500;
}

.view-exams-view .p-dropdown .p-dropdown-trigger-icon,
.view-exams-view .p-dropdown .p-dropdown-clear-icon{
    font-size: 13px;
    font-weight: 600;
    color: #9CA3AF;
}

.view-exams-view .p-dropdown .p-dropdown-clear-icon {
    margin-right: -10px;
}

.p-dropdown-item {
    color: #111827;
    font-size: 14px;
    font-weight: 400;
    -ms-overflow-style: none;
}

.p-dropdown-item::-webkit-scrollbar {
    display: none;
}

.view-exams-view .candidates-list-container .empty-candidates,
.view-exams-view .questions-list-container .empty-questions {
    height: 180px;
    width: 100%;
    background: #fff;
    margin-top: 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #6B7280;
    font-weight: 400;
    border: 1px solid #E5E7EB;
}
.rowdiv {display:flex; flex-direction:row;}

.end-exam-dialog-header .p-dialog-header .p-dialog-title {
    font-weight: 600 !important;
    font-size: 1.4rem !important;
}

.end-exam-dialog-header .w-4rem{
    width:3.5rem !important;
}

.end-exam-dialog-header .h-4rem{
    height:3.5rem !important;
}

.end-body-text p{
    padding:0px 10px;
    font-size: 18px;
}


/*.view-exams-view .header .evaluate-exam-button.tooltip {*/
/*    position: relative;*/
/*    display: inline-block;*/
/*    border-bottom: 1px dotted black;*/
/*}*/

/*.view-exams-view .header .evaluate-exam-button .tooltiptext {*/
/*    visibility: hidden;*/
/*    width: 100%;*/
/*    background-color: #c5c5c5;*/
/*    color: #fff;*/
/*    text-align: center;*/
/*    border-radius: 6px;*/
/*    padding: 5px 0;*/
/*    font-size:10px;*/

/*    !* Position the tooltip *!*/
/*    position: absolute;*/
/*    z-index: 1;*/
/*    top: 110%;*/
/*    left: 50%;*/
/*    margin-left: -60px;*/
/*}*/

/*.view-exams-view .header .evaluate-exam-button:hover .tooltiptext {*/
/*    visibility: visible;*/
/*}*/
