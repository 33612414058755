.login-view {
    background: #F9FAFB;
    font-family: 'Inter';
}

.login-view .content-holder {
    padding-top: 70px;
    padding-bottom: 40px;
}

.login-view .description {
    color: #6B7280;
    margin-top: -12.5px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 24px;
}

.login-view .footer {
    font-size: 16px;
    color: #6B7280;
}

.login-view .presidio-hire-logo {
    height: 27px;
}

.login-view .left-container {
    width: 65%;
}

.login-view .login-button {
    background: #0369A1;
    outline: none;
    border: none;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.login-view .login-button-container {
    height: 50px;
    width: 200px;
}

.login-view .login-container {
    height: 310px;
    width: 500px;
    background: #FFFFFF;
    padding: 50px 42px;
    border-radius: 8px;
}

.login-view .login-icon {
    height: 26px;
    width: 26px;
}

.login-view .login-label {
    margin-left: 10px;
    font-size: 16px;
}

.login-view .right-container {
    width: 35%;
}

.login-view .title {
    font-size: 24px;
    color: #1F2937;
}
