.add-candidates-container {}

.add-candidates-container .file-upload-container {
    margin-top: 25px;
}

.add-candidates-container .file-upload-container .upload-button-container,
.add-candidates-container .add-candidates-form .add-button-container {
    margin-top: 15px;
}


.add-candidates-container .add-candidates-form .field {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
}

.add-candidates-container .add-candidates-form .no-margin-top {
    margin-top: 0;
}

.add-candidates-container .add-candidates-form .field.split {
    width: 48%;
    margin-top: 0;
}

.add-candidates-container .add-candidates-form .field .label,
.add-candidates-container .add-candidates-form .field .sub-label{
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.add-candidates-container .add-candidates-form .field.span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.add-candidates-container .add-candidates-form .field .point-field {
    height: 44px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #D1D5DB;
    padding-left: 12.5px;
    font-size: 14px;
}

.add-candidates-container .add-candidates-form .field.span .input {
    margin-left: 10px;
}

.add-candidates-container .add-candidates-form .field .sub-label {
    margin-top: 5px;
}

.add-candidates-container .add-candidates-form .add-more-button {
    border: none;
    background: #fff;
    color: #00457B;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    padding-left: 0;
    cursor: pointer;
}

.add-candidates-container .add-candidates-form .field .input,
.add-candidates-container .add-candidates-form .field .p-multiselect,
.add-candidates-container .add-candidates-form .field .p-inputnumber {
    margin-top: 7.5px;
    width: 100%;
    height: 44px;
    font-size: 14px;
}

.add-candidates-container .add-candidates-form .field .p-inputnumber input {
    font-size: 14px;
}

.add-candidates-container .add-candidates-form .field .input .p-dropdown-label {
    font-size: 14px;
}

.label .input{
    position: relative;
}
