.action-button.p-button {
    height: 100%;
    width: 100%;
}

.action-button.primary.p-button{
    font-size: 14px;
    background: #0369A1;
    outline: none;
    border: none;
}


.action-button.primary.p-button:hover{
    background-color: #075985 !important;
    color: #FFFFFF !important;
}

.action-button.outline.p-button,
.action-button.outline.p-button:hover {
    font-size: 14px;
    background: #FFFFFF;
    color: #374151;
    outline: none;
    border: 1px solid #E5E7EB;
}

.action-button.text.p-button,
.action-button.text.p-button:hover {
    font-size: 14px;
    background: #FFFFFF;
    color: #0284C7;
    outline: none;
    border: none;
}
