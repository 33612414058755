.exam-card-1 {
    /*//box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
    width: 100%;
    margin:2% 0;
    background: #F7FAFB;
    border-radius: 8px;
    cursor: pointer;
    border:1px solid #e0e0e0 /*#f6f6f8;*/
}

.dashboard-exam-list-1{
    padding:3%;
}

.dashboard-exam-list-1 .title {
    color: #111827;
    font-size: 14px;
    font-weight: 500;
    padding-bottom:1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-exam-list-1 .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
    font-weight: 400;
    color: #3e4149;
}

.exam-card-1 .line-3 .university-name-col{
    width: 50%
}

.exam-card-1 .line-3 .exam-name-col{
    width:33%;
}

.exam-card-1 .line-3 .participants-col{
    width:33%;
}

.exam-card-1 .line-3 .date-col{
    padding-left:5%;
    width:33%;
}
.exam-card-1 .arrow-icon{
    padding-top:2%;
    color: #6B7280;
}

