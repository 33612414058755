.add-college-container {}

.add-college-container .add-college-form .add-button-container {
    margin-top: 0px;
}

.add-college-container .add-college-form .field {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
}


.add-college-container .add-college-form .no-margin-top {
    /*margin-top: 0;*/
}

.add-college-container .add-college-form .field .label,
.add-college-container .add-college-form .field .sub-label{
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.add-college-container .add-college-form .field .error-label {
    font-size: 12px;
}

.add-college-container .add-college-form .field .input,
.add-college-container .add-college-form .field .p-multiselect,
.add-college-container .add-college-form .field .p-inputnumber {
    margin-top: 7.5px;
    width: 100%;
    height: 44px;
    font-size: 14px;
}

.add-college-container .add-college-form .field .p-inputnumber input {
    font-size: 14px;
}

.add-college-container .add-college-form .field .input .p-dropdown-label {
    font-size: 14px;
}
