.candidate-exam-container {
    height: 80%;
    width: 500px;
    display: flex;
    flex-direction: column;
}

.candidate-exam-container .candidate-exam-form{
    background-color: white;
    padding:30px;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column; 
}

.candidate-exam-container .text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
}
.candidate-exam-container .text-base{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-overflow: ellipsis;
}

.truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

.candidate-exam-container .text, .candidate-exam-container .text-base , .logo-container{
    padding:5px;
}

.candidate-exam-container .candidate-exam-form .form-field .label{
    color: #4B5563;
    margin-bottom:5px;
    font-size: 14px;
    font-weight: 500;
}

.candidate-exam-container .candidate-exam-form .form-field .formInputText{
    width:100%;
}

.candidate-exam-container .candidate-exam-form .form-field .login-button-container{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-end;
}

.candidate-exam-container .candidate-exam-form .form-field .login-button-container .login-button{
    margin-top:1em;
    margin-bottom:0.5em;
}

.footer {
    font-size: 16px;
    color: #6B7280;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    margin-top: 1em;
}

@media only screen and (max-width: 600px) {
    .candidate-exam-container {
        width:90%;
    }
  }