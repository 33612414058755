
.list-colleges-container .list-colleges-table {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    /*overflow: hidden;*/
    height: 291px;
    background: #fff;
    margin-top: 10px;
}

.list-colleges-container .row {
    height: 50px;
    display: grid;
    background: #fff;
    border-bottom: 1px solid #E5E7EB;
    grid-template-rows: 1fr;
    grid-template-columns: 25% 25% 20% 20% 10%;
    grid-template-areas: "name role email poc elipsis";
}

.list-colleges-container .row.row-header {
    height: 40px;
    background: #F9FAFB;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.list-colleges-container .row.last-row {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.list-colleges-container .row .cell.name {
    grid-area: name;
    width: 715px;
    height: 50px;
    padding: 0 20px;
    font-weight: 500;
    color: #111827;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}
.list-colleges-container .row .cell.poc{
    grid-area: poc;
}

.list-colleges-container .row .cell.name .text-ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-colleges-container .row .cell.role {
    grid-area: role;
    font-weight: 400;
    color: #4B5563;
    padding-left: 15px;
}

.list-colleges-container .row .cell.email {
    grid-area: email;
    font-weight: 400;
    color: #4B5563;
}

.list-colleges-container .row .cell.elipsis {
    grid-area: elipsis;
    font-weight: 400;
    color: #4B5563;
    padding-right: 20px;
    align-items: center;
    justify-content: flex-end;
}

.list-colleges-container .row .cell {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
}

.list-colleges-container .row.row-header .cell {
    height: 40px;
    color: #6B7280;
    font-size: 12px;
    font-weight: 500;
}

.list-colleges-container .footer {
    margin-top: 10px;
    /*padding: 0 20px;*/
}

.list-colleges-container .footer .result-details {
    color: #374151;
    font-size: 14px;
    font-weight: 400;
}

/*.list-colleges-container .footer .pagination-buttons .previous-button {*/
/*    margin-right: 15px;*/
/*}*/

.list-colleges-container .footer .pagination-buttons .college-next-button {
    margin-left: 15px;
}


.list-colleges-container .filter-fields .p-inputgroup .p-inputtext {
    width: 250px!important;
    height: 44px;
    padding-right: 10px;
}

.list-colleges-container .overlay {
    height: 80px;
    width: 140px;
    position: relative;
    border-radius: 8px;
    top: 50px;
    right: -20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-width: 150px;
    z-index: 100;
}

.list-colleges-container .overlay .overlay-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
    cursor: pointer;
}

.list-colleges-container .overlay .overlay-item:hover {
    background: #f5f5f5;
}

.list-colleges-container .overlay .overlay-item .text {
    margin-left: 10px;
}

.list-colleges-container .overlay .overlay-item img {
    height: 17.5px;
    width: 17.5px;
}


.list-colleges-line {
    width: 100%;
    height: 1px;
    background: #E5E7EB;
    margin-top: 40px;
    margin-bottom: 10px;
}
