.view-exam-header-card {
    height: 90px;
    width: 240px;
    background: #fff;
    border-radius: 8px;
    padding: 20px 17px;
}

.view-exam-header-card .title {
    font-weight: 600;
    font-size: 10px;
    color: #6B7280;
}

.view-exam-header-card .content {
    height: 25px;
    color: #111827;
    font-weight: 700;
    font-size: 23px;
}

.view-exam-header-card .sub-content {
    color: #6B7280;
    font-size: 11px;
    font-weight: 500;
    margin-left: 5px;
}

.view-exam-header-card .percantage{
    font-size: 12px;
}