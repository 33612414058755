.list-questions-reevaluate-container {
    margin-top: 30px;
    margin-bottom: 20px;
}

.list-questions-reevaluate-container-header{
    margin-top:25px;
}

.list-questions-reevaluate-container-header .title{
    margin-top:5px;
    font-size: 22px;
}

.list-questions-reevaluate-container-header .reevaluate-search{
    height:42px;
    font-size: 0.8rem;
    border: 1px solid #e5e7eb;
}

.list-questions-reevaluate-container-header .reevaluate-search:focus{
    height:42px;
    font-size: 0.8rem;
    border: 1px solid #e5e7eb !important;
    box-shadow:none !important;
}

.list-questions-reevaluate-container .header .title {
    font-size: 18px;
    font-weight: 500;
    color: #111827;
}

.list-questions-reevaluate-container .header .title .upload-status {
    margin-left: 10px;
    font-size: 12px;
    padding-top:5px;
}

.list-questions-reevaluate-container .list-question-header-button {
    margin-right:10px;
}

.list-questions-reevaluate-container .header .title .upload-status .info-icon {
    font-size: 11px;
    font-weight: 600;
    margin-right: 3px;
}

.list-questions-reevaluate-container .header .title .upload-status.success {
    color: #047857;
}

.list-questions-reevaluate-container .header .title .upload-status.error {
    color: #BE123C;
}

.list-questions-reevaluate-container .header .title .upload-status.started {
    color: #CA8A04;
}

.list-questions-reevaluate-container .list-questions-reevaluate-table {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    /*overflow: hidden;*/
    /*height: 440px;*/
    background: #fff;
}

.list-questions-reevaluate-container .row {
    height: 50px;
    display: grid;
    background: #fff;
    border-bottom: 1px solid #E5E7EB;
    grid-template-rows: 1fr;
    grid-template-columns: 2% 50% 25% 23%;
    grid-template-areas: "edit question exam category more-info";
}

.list-questions-reevaluate-container .row .cell.edit {
    grid-area: edit;
    font-weight: 400;
    color: #4B5563;
    margin-left: 15px;
}

.list-questions-reevaluate-container .row.row-header {
    height: 40px;
    background: #F9FAFB;
}

.list-questions-reevaluate-container .row .cell.question-reevaluate {
    grid-area: question;
    width: 715px;
    height: 50px;
    padding: 0 20px;
    font-weight: 500;
    color: #111827;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-questions-reevaluate-container .row .cell.question .text-ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.list-questions-reevaluate-container .row .cell.exam {
    grid-area: exam;
    font-weight: 400;
    color: #4B5563;
    padding-left: 15px;
    justify-content: center;
    align-content: center;
}

.list-questions-reevaluate-container .row .cell.category {
    grid-area: category;
    font-weight: 400;
    color: #4B5563;
    justify-content: center;
    align-content: center;
}

/*.list-questions-reevaluate-container .row .cell.more-info {*/
/*    grid-area: more-info;*/
/*    font-weight: 400;*/
/*    color: #0284C7;*/
/*    justify-content: flex-end;*/
/*    padding-right: 20px;*/
/*}*/

.list-questions-reevaluate-container .row .cell {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
    text-align: center;
}

.list-questions-reevaluate-container .row.row-header .cell {
    height: 40px;
    color: #6B7280;
    font-size: 12px;
    font-weight: 500;
}

.list-questions-reevaluate-container .footer {
    margin-top: 10px;
}

.list-questions-reevaluate-container .footer .result-details {
    color: #374151;
    font-size: 14px;
    font-weight: 400;
}

.list-questions-reevaluate-container .footer .pagination-buttons .qns-previous-button {
    margin-right: 15px;
}

.list-questions-reevaluate-container .footer .pagination-buttons .qns-next-button {
    margin-right: 0;
}

.p-dialog.question-dialog .p-dialog-header .p-dialog-title {
    font-size: 16px;
    color: #111827;
}

.filter-container .download-p-icon-right{
    width:30px;
    background: #FFFFFF;
    border-left: 1px solid #E5E7EB;
}

.list-questions-reevaluate-container .filter-container .p-dropdown{
    background: none;
    border:none;
}
.re-evaluate-button .p-button{
    background: green;
}
.view-exams-view .filter-fields .p-inputgroup .p-inputtext {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 0;
}
.filter-fields .p-inputgroup .search .p-inputtext {
    border-right: 1px solid #E5E7EB;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0369A1!important;
    background: #0369A1!important;
}
.reevaluate-search {
    width: 100% !important;
}
.empty-questions-reevalaute {
    height: 450px;
}

.list-questions-reevaluate-container-header .title-container .back-navigation .pi-angle-left {
    color: #64748b;
    font-size: 14px;
}

.list-questions-reevaluate-container-header .title-container .back-navigation .label {
    color: #475569;
    font-size: 14px;
    margin-left: 2.5px;
}

/*.question-reevaluate .field-checkbox{*/
/*    margin-left:-5px;*/
/*    z-index:10;*/
/*}*/
