.ongoing-exam-card {
    padding-left: 20px;
    padding-top: 10px;
    background: #00457B !important;
    margin: 10px;
    height: 150px;
    width: 25%;
    /*margin-top: 22px;*/
    cursor: pointer;
    border-radius: 8px;
}
.ongoing-campus-drive-name{
    padding-bottom: 2%;
}

.card-footer {
    position: relative;
    bottom: -50px;
    width: 97%;
    justify-content: space-between;


    font-family: 'Inter', ui-rounded;
    font-style: normal;
    font-weight: lighter;
}

.angle-icon {

    color: #6489ab;
    background: #00457B;
    width: 9px;
    height: 16px;


}
.ongoing-exam-card .arrow-icons{
    color: #ffffff;
    margin-left:250px;
}

