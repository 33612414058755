.mark-sheet-footer-flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px 10px;
    color: #3f3f46;
    font-family: Inter;
    font-weight: 400;
    font-feature-settings: 'salt' on;
}

.timespent-container,.points-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mark-next-container, .navigator-review-button{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.next-button, .bookmark-button, .back-button,.timespent-container{
    margin-top:7px;
}
.mark-sheet-point-display,.evaluated-points-container{
    margin: 0px 5px;
}