.add-evaluation-filter-container {}

.add-evaluation-filter-container .add-evaluation-filter-form .add-button-container {
    margin: 15px 0px;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 0;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field-margin-top {
    margin: 5px;
}

.add-evaluation-filter-container .score{
    padding-bottom: 5px;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field .label,
.add-evaluation-filter-container .add-evaluation-filter-form .field .sub-label{
    color: #4B5563;
    font-size: 16px;
    font-weight: 500;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field .label-range{
    color: #4B5563;
    font-size: 14px;
    font-weight: 400;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field .error-label {
    font-size: 10px;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field .input-number {
    margin-top: 7.5px;
    width: 100%;
    height: 44px;
    font-size: 14px;
    border-radius:8px;
    border:1px solid #a4a4a4;
    padding: 0px 7px;
}

.add-evaluation-filter-container .add-evaluation-filter-form .content{
    padding:15px 0px 10px 0px;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field .input,
.add-evaluation-filter-container .add-evaluation-filter-form .field .p-multiselect,
.add-evaluation-filter-container .add-evaluation-filter-form .field .p-inputnumber {
    margin-top: 7.5px;
    width: 100%;
    height: 44px;
    font-size: 14px;
}

.range-slider{
    margin:20px;
}

.p-slider{
    height:10px !important;
}

.range-slider .p-slider .p-slider-range {
    background: #0369A1;
    background-color: #0369A1;;
}
.range-slider .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #0369A1;
    border-color: #0369A1;
}

.range-slider .p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 2px solid #0369A1;
    border-radius: 50%;
    transition: none;
}

.result-student-count{
    font-size:14px;
}

.separator .separator-line{
    border:0.5px solid #ababab;
}

.add-evaluation-filter-container .error-label {
    font-size: 12px;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field .p-inputnumber input,
.add-evaluation-filter-container .add-evaluation-filter-form .field .p-inputnumber input-number{
    font-size: 14px;
}

.add-evaluation-filter-container .add-evaluation-filter-form .field .input .p-dropdown-label {
    font-size: 14px;
}
