.sheet-display-canvas {
    width: 100%;
    /*margin-top: 150px;*/
    background-color: white;
    border: 1px solid #F9FAFB;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: Inter;
}
.evaluation-sheet-display-canvas {
    width: 800px;
    margin-top: 150px;
    background-color: white;
    border: 1px solid #F9FAFB;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: Inter;
}

.sheet-display-question {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #1F2937;
    margin-left: 4%;
    margin-right: 4%;
}

.sheet-display-solution {
    margin-left: 4%;
    margin-right: 4%;
}

.sheet-display-question-head {
    margin-top: 2%;
    margin-left: 4%;
    font-weight: 400;
    font-feature-settings: 'salt' on;
    color: #3f3f46;
}

.question-horizontal-line{
    border-top: 1px solid #E5E7EB;
    margin: 0.5em 0em;
}
