.create-exam-view {
    width: 350px;
    /*padding-top: 120px;*/
}

.create-exam-view .campus-drive .input .p-dropdown-label {
    font-size: 14px;
}

.create-exam-view .create-exam-form {}

.create-exam-view .field {
    width: 100%;
    margin-top: 35px;
}

.create-exam-view .field .error-label {
    margin-left: 12px;
}

.create-exam-view .field .label {
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.create-exam-view .field .input{
    margin-top: 7.5px;
    width: 100%;
    height: 42px;
    font-size: 14px;
}

.create-exam-view .header {
    margin-top: 33px;
}

.create-exam-view .header .back-navigation {}

.create-exam-view .header .back-navigation .label {
    font-size: 14px;
    color: #475569;
    margin-left: 2.5px;
}

.create-exam-view .header .back-navigation .pi-angle-left {
    font-size: 14px;
    color: #64748B;
}

.create-exam-view .header .title {
    margin-top: 5px;
    font-size: 22px;
    font-weight: 700;
}

.create-exam-view .submit-button-container {
    margin-top: 35px;
    margin-bottom: 35px;
}

.create-exam-view .submit-button.p-button {
    background: #0369A1;
    width: 152px;
    height: 44px;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    transition: width 0.3s ease-in-out;
}

.create-exam-view .submit-button.icon-present.p-button {
    width: 170px;
}

.create-exam-view .submit-button.p-button:hover {
    background: #0369A1;
}

.p-datepicker {
    transform: scale(0.9)!important;
    left: 38%!important;
}

.p-calendar .p-inputtext {
    font-size: 14px;
    color: #4B5563;
}

#flxbox{
    width:55%
}
