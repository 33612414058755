.list-exam-row-skeleton {
    height: 50px;
    background: #F9FAFB;
    border-left: 1.5px solid #E5E7EB;
    border-right: 1.5px solid #E5E7EB;
}

.list-exam-row-skeleton.shade {
    background: #FFFFFF;
}

.list-exam-row-skeleton.last-item {
    border-bottom: 1.5px solid #E5E7EB;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.list-exam-row-skeleton .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
}

.list-exam-row-skeleton .campus-drive-col {
    width: 25%;
}

.list-exam-row-skeleton .university-col {
    width: 20%;
}

.list-exam-row-skeleton .exams-col {
    width: 20%;
}

.list-exam-row-skeleton .students-col {
    width: 15%;
}

.list-exam-row-skeleton .view-campus-drive {
    width: 20%;
    padding-right: 24px;
}
