.navbar-comp {
    height: 70px!important;
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
    font-family: Inter, serif;
    z-index: 10;
}

.navbar-comp .navigation {}

.navbar-comp .navigation-links {
    width: 525px;
}

.navbar-comp .navigation-links-seval{
    width: 290px;
}

.navbar-comp .navigation-links a {
    height: 100%;
    text-decoration: none;
    width: auto;
}

.navbar-comp .navigation-links .active {
    border-bottom: 2px solid #00457B;
}

.navbar-comp .navigation-links .active .link{
    color: #00457B;
    padding-top: 2px;
}

.navbar-comp .navigation-links .link {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #878D97;
    font-weight: 500;
    font-size: 15px;
}

.navbar-comp .profile {
    margin-right: 15px;
}

.navbar-comp .profile-avatar {
    height: 40px;
    width: 40px;
    margin-right: 15px;
    font-size: 15px;
}

.navbar-comp .profile-drop {
    height: 17.5px;
    width: 17.5px;
    transition: all 0.2s ease-in-out;
    margin-right: 55px;
}

.navbar-comp .profile-dropdown-button {
    width: 300px;
    height: 10px;
    border: 1px solid white;
    background-color: white;
    color: black;

}
.navbar-comp .profile-dropdown-list {
    border: 1px solid transparent;
    background-color: white;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    width: 100px;
}
.navbar-comp .profile-dropdown {
    position:absolute;
    top:22px;
    right:12px;
}
.navbar-comp .outerDropDown
{
    position:relative;
}

.profile-drop .p-button:enabled:hover{
    background-color: white !important;
    color: black !important;
    border: none !important;
}
.profile-drop .p-button:focus{
    box-shadow: none !important;
}

.navbar-comp .profile-name {
    color: #111827;
    font-size: 15px;
}

.navbar-comp .profile-role {
    color: #6B7280;
    font-size: 13px;
}

.navbar-comp .presidio-hire-logo {
    height: 27px;
}

.navbar-comp .presidio-hire-logo-container {
    margin-left: 25px;
}
