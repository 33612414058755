.roles-list-view {}

.roles-list-view .header {
    margin-top: 33px;
}

.roles-list-view .header .new-user {}

.roles-list-view .header .new-user .new-user-button.p-button {
    height: 44px;
    width: 150px;
    font-size: 14px;
    background: #0369A1;
    outline: none;
    border: none;
}

.roles-list-view .header .new-user .new-user-button.p-button:focus {
    outline: none;
    border: none;
}

.roles-list-view .header .new-user .new-user-button.p-button:hover {
    background-color: #075985 !important;
    color: #FFFFFF !important;
}

.roles-list-view .header .title {
    font-size: 22px;
}

.roles-list-view .roles-list-container .empty-roles {
    height: 180px;
    width: 100%;
    background: #fff;
    margin-top: 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #6B7280;
    font-weight: 400;
    border: 1px solid #E5E7EB;
}

.roles-list-view .roles-list-container .header .filter-container {

}

.roles-list-view .filter-fields {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
}

.roles-list-view .filter-fields .p-inputgroup .p-inputtext {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 0;
}

.roles-list-view .filter-fields .p-inputgroup .search .p-inputtext {
    border-right: 1px solid #E5E7EB;
}

.roles-list-view .filter-fields .p-inputgroup .p-inputtext::placeholder {
    font-size: 13px;
}

.filter-item {
    color: #6B7280;
    font-size: 11px;
    font-weight: 700;
}

.filter-fields .pi-search {
    font-size: 12px;
    font-weight: 600;
    margin-top: -5px;
}

.roles-list-view .filter-icon {
    margin-left: 15px;
}

.list-role-container .p-dropdown:not(.p-disabled).p-focus,
.list-role-container .p-dropdown:not(.p-disabled).p-focus {
    outline: none!important;
    outline-offset: 0!important;
    box-shadow: none!important;
    border: none!important;
}

.roles-list-view .p-dropdown .p-dropdown-trigger {
    width: 30px;
    margin-right: 5px;
}

.roles-list-view .p-dropdown {
    width: 120px!important;
    border: none;
    background: #F9FAFB;
}

.roles-list-view .filter-fields .p-inputgroup .p-inputtext.p-dropdown-label {
    width: 100px;
    color: #374151;
    font-size: 14px;
    font-weight: 500;
}

.roles-list-view .p-dropdown .p-dropdown-trigger-icon,
.roles-list-view .p-dropdown .p-dropdown-clear-icon{
    font-size: 13px;
    font-weight: 600;
    color: #9CA3AF;
}

.roles-list-view .p-dropdown .p-dropdown-clear-icon {
    margin-right: -10px;
}

.p-dropdown-item {
    color: #111827;
    font-size: 14px;
    font-weight: 400;
    -ms-overflow-style: none;
}

.p-dropdown-item::-webkit-scrollbar {
    display: none;
}
