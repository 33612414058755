
.review-list-container {
    margin-bottom: 35px;
    width:100%;
    align-self: center;
}

.review-list-container .list-header {
    height: 40px;
    background: #F9FAFB;
    border: 1.5px solid #E5E7EB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.review-list-container .list-header .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #6B7280;
}