.campus-drive-list-view {}

.campus-drive-list-view .drive-list-container {
    margin-top: 35px;
}

.campus-drive-list-view .drive-list-container .list-header {
    height: 40px;
    background: #F9FAFB;
    border: 1.5px solid #E5E7EB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.campus-drive-list-view .drive-list-container .list-header .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #6B7280;
}

.campus-drive-list-view .drive-list-container .list-header .campus-drive-col {
    width: 30%;
}

.campus-drive-list-view .drive-list-container .list-header .university-col {
    width: 20%;
}
.campus-drive-list-view .drive-list-container .list-header .status-col {
    width: 20%;
}
.campus-drive-list-view .drive-list-container .list-header .exams-col {
    width: 20%;
}

.campus-drive-list-view .drive-list-container .list-header .students-col {
    width: 20%;
}

.campus-drive-list-view .header {
    margin-top: 33px;
}

.campus-drive-list-view .header .new-drive {}

.campus-drive-list-view .header .new-drive .download-button.p-button {
    height: 44px;
    font-size: 14px;
    background: #0369A1;
    outline: none;
    border: none;
}

.campus-drive-list-view .header .new-drive .search-drive-button.p-button:focus {
    outline: none;
    border: none;
}
.campus-drive-list-view .header .search-drive .search-drive-button.p-button {
    height: 44px;
    font-size: 14px;
    background: #0369A1;
    outline: none;
    border: none;
    margin-right: 5px;
}

.campus-drive-list-view .header .new-drive .download-button .p-button:focus {
    outline: none;
    border: none;
}

.campus-drive-list-view .header .new-drive .download-button .p-button:hover{
    background-color: #075985 !important;
    color: #FFFFFF !important;
    transition: 0.3s !important;
}

.campus-drive-list-view .header .title {
    font-size: 22px;
}

.campus-drive-list-view .no-data {
    margin-top: 35px;
    color: #6B7280;
    font-size: 16px;
}

.campus-drive-list-view .header .filter-container {

}

.campus-drive-list-view .filter-fields {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
}

.campus-drive-list-view .filter-fields .p-inputgroup .p-inputtext {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 0;
}

.campus-drive-list-view .filter-fields .p-inputgroup .search .p-inputtext {
    border-right: 1px solid #E5E7EB;
}

.campus-drive-list-view .filter-fields .p-inputgroup .p-inputtext::placeholder {
    font-size: 13px;
}

.drive-result-details {
    color: #374151;
    font-size: 14px;
    font-weight: 400;
}

.filter-item {
    color: #6B7280;
    font-size: 11px;
    font-weight: 700;
}

.filter-fields .pi-search {
    font-size: 12px;
    font-weight: 600;
    margin-top: -5px;
}

.campus-drive-list-view .filter-icon {
    margin-left: 15px;
}
.campus-drive-list-view .filter-border {
    border: 1px solid #E5E7EB;
    background: #FFFFFF;
    border-radius: 5px;
}
.drive-list-container .drive-footer {
    margin-top: 10px;
    color: #374151;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px 0;
}

.drive-result-details{
    padding-left: 20px;
    margin-top: 20px;
}

.next-button{
    /*padding-left: 10px;*/
    margin-left: 10px;
    margin-top: 5px;
}
.previous-button{
    margin-top: 7px;
}
.next-button:hover{
    border: 1px solid #0369A1;
    border-radius: 6px;
}
.previous-button:hover{
    border: 1px solid #0369A1;
    border-radius: 6px;
}
search-drive-button{
    margin-right: 5px!important;
}
.no-campusDrive-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    height: 240px;
}
.drowdown-outline{
    width: 200px;
}

.dialog-align{
    padding: 10px;
}
.campus-drive-list-view .filter-container .p-dropdown {
    border: none !important;
}
.dialog-align .add-button-container .add-button {
    padding-top: 1%;
    display: flex;
    width: 50%;
}
.campus-drive-list-view .title-container .back-navigation .pi-angle-left {
    color: #64748b;
    font-size: 14px;
}

.campus-drive-list-view .title-container .back-navigation .label {
    color: #475569;
    font-size: 14px;
    margin-left: 2.5px;
}

.campus-drive-list-view .back-navigation{
    margin-bottom: 5px;
}
