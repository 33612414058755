.time-select-field {
    width: 163px;
    height: 100%;
}

.time-select-field .time-field-container {
    height: 42px;
    margin-top: 7.5px;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #D1D5DB;
}

.time-select-field .ampm-container {
    width: 80px;
}

.time-select-field .ampm-container .input {
    margin-top: 0!important;
    border: none;
    height: 40px!important;
    outline: none;
}

.time-select-field .ampm-container .input .p-inputtext {
    font-weight: 400;
    font-size: 14px;
    color: #6B7280;
    width: 44px;
    text-overflow: unset;
}

.time-select-field .ampm-container .input .p-dropdown-trigger {
    width: 38px;
}

.time-select-field .time-container {
    width: 91px;
}

.time-container .hours {
    width: 40px;
    height: 100%;
    border: none;
    border-radius: 10px;
    outline: none;
    text-align: right;
    padding-top: 4px;
    padding-right: 5px;
    -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
.time-container input::-webkit-outer-spin-button,
.time-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
.time-container input[type=number] {
    -moz-appearance: textfield;
}

.time-container input::placeholder {
    color: #a9acb2;
}

.time-container .mins {
    width: 40px;
    height: 100%;
    border: none;
    border-radius: 6px;
    outline: none;
    text-align: left;
    padding-top: 4px;
    padding-left: 5px;
}

.time-select-field .text {
    font-weight: 400;
    font-size: 14px;
    color: #6B7280;
}
