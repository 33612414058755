html, body, #root {
  height: 100%;
  width: 100%;
  background: #F9FAFB;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-toast {
  transform: scale(0.8);
  z-index: 9999;
}

.p-dialog-header {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.p-dialog-content {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.column-container{
  display: flex;
  flex-direction: column;
}
.row-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.pi{
  font-size: 12px !important;
}