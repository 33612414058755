.question-navigator {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
}

.question-navigator-close {
    flex-grow: 1;
    height: 100%;
}

.question-navigator-questions {
    flex-grow: 9;
}

.question-number-display-set {
    height: 100%;
}

.question-navigator-wrap {
    height: 80%;
}