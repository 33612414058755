.exam-card-skeleton {
    /*//box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
    width: 100%;
    margin:2% 0;
    background: #F7FAFB;
    border-radius: 8px;
    border:1px solid #f6f6f8;
}

.dashboard-exam-list-skeleton{
    padding:3%;
}


.dashboard-exam-list-skeleton .cell {
    height: 100%;
}

.exam-card-skeleton .title {
    font-weight: 600;
    font-size: 10px;
    color: #6B7280;
    padding-bottom: 1%;
}

.exam-card-skeleton .sub-content {
    font-weight: 600;
    font-size: 10px;
    color: #6B7280;
}

.exam-card-skeleton .university-name-col{
    width: 45%;
}

.exam-card .exam-name-col{
    width:25%;
}

.exam-card .participants-col{
    width:30%;
}

.exam-card .percentage-passed-col{
    width:20%;
}

.exam-card .date-col{
    width:20%;
}
