.view-exam-header-skeleton .title {
    width: 150px;
    height: 25px;
    margin-top: 3px
}

.view-exam-header-skeleton .sub-title {
    width: 300px;
    height: 25px;
    margin-top: 3px
}

.view-exam-header-skeleton .edit-exam {
    height: 44px;
}
