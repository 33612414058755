.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    background: #F9FAFB;
    z-index: 20;
}

.loading .loader-container {
    height: 40px;
    width: 40px;
    /*background: lightblue;*/
    margin-top: 10px;
    /*border-radius: 50%;*/
}

.loading .loader-container .loader-vector-1 {
    top: 2px;
    left: 18px;
    animation-name: loading-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-delay: 0s;

}

.loading .loader-container .loader-vector-2 {
    top: 7px;
    left: 22px;
    animation-name: loading-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-delay: 0.1s;
}

.loading .loader-container .loader-vector-3 {
    top: 18px;
    left: 18px;
    animation-name: loading-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-delay: 0.2s;
}

.loading .loader-container .loader-vector-4 {
    top: 25px;
    left: 5.5px;
    animation-name: loading-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-delay: 0.3s;
}

.loading .loader-container .loader-vector-5 {
    top: 29px;
    left: -9px;
    animation-name: loading-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-delay: 0.4s;
}

.loading .loader-container .loader-vector-6 {
    top: 25px;
    left: -23px;
    animation-name: loading-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-delay: 0.5s;
}

.loading .loader-container .loader-vector-7 {
    top: 18px;
    left: -35px;
    animation-name: loading-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-delay: 0.6s;
}

.loading .loader-container .loader-vector-8 {
    top: 7.5px;
    left: -39px;
    animation-name: loading-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-delay: 0.7s;
}

.loading .presidio-hire-logo {
    height: 30px;
}

.not-loading {
    display: none;
}

@keyframes loading-animation {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
