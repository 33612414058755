.candidate-nav-comp {
    height: 70px!important;
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
    font-family: Inter, serif;
    z-index: 10;
}
.nav-row-container{
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
}
.candidate-nav-comp {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #878D97;
    font-weight: 500;
    font-size: 15px;
}


.candidate-nav-comp i{
    color: #6B7280;
}
.candidate-profile-container{
    padding: 0em 0.5em;
    margin-right:1em;
    margin-left:0.5em;
}
.candidate-profile-container .candidate-profile-name {
    color: #111827;
    font-size: 15px;
}

.candidate-profile-container .candidate-profile-role {
    color: #6B7280;
    font-size: 13px;
}

.candidate-nav-comp .presidio-hire-logo {
    height: 27px;
}

.candidate-nav-comp .presidio-hire-logo-container {
    margin-left: 1em;
}

.timedisplay{
    /* 40:00 */
    font-family: 'inter';
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 91% */
    text-align: right;
    font-feature-settings: 'salt' on;
    /* gray/700 */
    color: #374151;
}

@media only screen and (max-width: 450px){
    .presidio-hire-logo-container .presidio-hire-logo{
        height:15px;
    }
    .timedisplay{
        font-size:16px;
    }
    .candidate-profile-container .candidate-profile-name{
        font-size:12px;
    }
    .candidate-profile-container .candidate-profile-role{
        font-size:11px;
    }
}
