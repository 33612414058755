.settings-view {

}

.settings-view .settings-layout {
    width: 1300px;
}

.settings-view .header {
    margin-top: 33px;
}

.settings-view .header .new-college .new-college-button.p-button:focus {
    outline: none;
    border: none;
}

.settings-view .header .title {
    font-size: 22px;
}

.colleges-list-container .header .title,
.categories-list-container .header .title {
    font-weight: 600;
}

.settings-view .settings-layout .header .additional-setting-button{
    float: right;
}

.settings-view .settings-layout .header .additional-setting-button Button{
    background: #0369A1;
    outline: none;
    border: none;
}

.settings-view .colleges-list-container .empty-colleges,
.settings-view .categories-list-container .empty-categories {
    height: 180px;
    width: 100%;
    background: #fff;
    margin-top: 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #6B7280;
    font-weight: 400;
    border: 1px solid #E5E7EB;
}

.settings-view .colleges-list-container .header .filter-container {

}

.settings-view .filter-fields {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
}

.settings-view .filter-fields .p-inputgroup .p-inputtext {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 0;
}

.settings-view .filter-fields .p-inputgroup .search .p-inputtext {
    border-right: 1px solid #E5E7EB;
}

.settings-view .filter-fields .p-inputgroup .p-inputtext::placeholder {
    font-size: 13px;
}

.filter-item {
    color: #6B7280;
    font-size: 11px;
    font-weight: 700;
}

.filter-fields .pi-search {
    font-size: 12px;
    font-weight: 600;
    margin-top: -5px;
}

.additional-Setting .field{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
}

.additional-Setting .field .input{
    margin-top: 7.5px;
}

.p-inputtext, .p-chips{
    width: 100%;
}

.additional-Setting .field .label{
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.additional-Setting .field .input-edited{
    font-size: 12px;
    color: #10B981;
    margin-top: 5px;
}

.additional-Setting .update-button{
    margin-top: 9.5px;
    justify-content: center;
    width: 30%;
    float: right;
}

.additional-Setting .update-button{
    margin-top: 9.5px;
    justify-content: center;
    width: 30%;
    float: right;
}
