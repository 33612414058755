.markSheet-navigator {
    position: fixed;
    z-index: 1;
    right: 0;
    top: 47%;
    margin-right: 3px;
}


.p-button.p-button-info.p-button-outlined {
    border-radius: 50% 50% 50% 50%;
    margin: 4px;
    color: #0369A1 !important;
    background-color: white !important;
}

.p-button.p-button-info {
    background: #0369A1 !important;
    border: 1px solid #0369A1 !important;
}

.navigator-panel {
    width: 100%;
    background-color: white;
    border: 1px solid #F9FAFB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 2% 0 0 2%;
    height: 100%;
}

.p-sidebar .p-sidebar-header {
    padding: 0.01rem !important;
}

.candidate-mark-sheet-panel {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    align-items: center;
}

.candidate-mark-sheet-close {
    flex-grow: 1;
}

.candidate-mark-sheet-questions {
    flex-grow: 18;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.question-flex-heading {
    align-items: flex-start;
    margin-left: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.question-flex-content {
    height: 100%;
}

.sheet-candidate-display-canvas {
    max-width: 1200px;
    min-width: 880px;
    background-color: white;
    border: 1px solid #F9FAFB;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 2%;
}

.sheet-candidate-display-canvas-label {
    color: #3f3f46;
    margin-top: 0;
    font-family: Inter;
    font-weight: 400;
    font-feature-settings: 'salt' on;
    font-size: 14px;
}

.sheet-candidate-display-canvas-skeleton {
    max-width: 1200px;
    min-width: 880px;
    border-radius: 10px;
    border: 1px solid #F9FAFB;
    height: 100px;
    margin-top: 2%;
    margin-bottom: 2%;
}

.mark-sheet-question-display-canvas-skeleton {
    max-width: 1200px;
    min-width: 880px;
    border-radius: 10px;
    border: 1px solid #F9FAFB;
    height: 500px;
    margin-top: 2%;
    margin-bottom: 2%;
}