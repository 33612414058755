.list-review-row-skeleton {
    height: 50px;
    background: #F9FAFB;
    border-left: 1.5px solid #E5E7EB;
    border-right: 1.5px solid #E5E7EB;
    
}

.list-review-row-skeleton.shade {
    background: #FFFFFF;
}

.list-review-row-skeleton:last-child {
    border-bottom: 1.5px solid #E5E7EB;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.list-review-row-skeleton .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
    text-overflow: ellipsis;
    
}





