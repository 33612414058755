.campus-drive-list-skeleton {
    margin-top: 35px;
}

.campus-drive-list-skeleton .list-header {
    height: 40px;
    background: #F9FAFB;
    border: 1.5px solid #E5E7EB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.campus-drive-list-skeleton .list-header .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #6B7280;
}

.campus-drive-list-skeleton .list-header .campus-drive-col {
    width: 20%;
}

.campus-drive-list-skeleton .list-header .university-col {
    width: 30%;
}

.campus-drive-list-skeleton .list-header .exams-col {
    width: 25%;
}

.campus-drive-list-skeleton .list-header .students-col {
    width: 20%;
}

.campus-drive-list-skeleton .list-header .view-campus-drive {
    width: 5%;
}
