.admin-view {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 70px 1fr;
    grid-template-areas: "section1" "section2";
}

.admin-view .section-1 {
    grid-area: section1;
}

.admin-view .section-2 {
    grid-area: section2;
}
