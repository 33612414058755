.upload-field {
    width: 100%;
    height: 167px;
}

.upload-field .file-input-field {
    opacity: 0;
    position: relative;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: lightblue;
}

.upload-field .header {
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.upload-field .upload-container {
    border: 2px dashed #D1D5DB;
    border-radius: 8px;
    width: 100%;
    height: 140px;
    margin-top: 10px;
}

.upload-field .upload-container .icon-container {
    margin-bottom: 10px;
}

.upload-field .upload-container .text-1 {
    font-size: 14px;
    color: #00457B;
    font-weight: 500;
}

.upload-field .upload-container .text-2 {
    font-size: 14px;
    color: #4B5563;
    font-weight: 500;
}

.upload-field .upload-container .text-3 {
    margin-top: 10px;
    font-size: 12px;
    color: #6B7280;
    font-weight: 400;
}

.file-present {
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    height: 60px;
    padding: 0 15px;
}

.file-present .text-container {

}

.file-present .text-container .text {
    font-size: 14px;
    color: #4B5563;
    font-weight: 500;
}

.file-present .text-container .validation-status-container {
    margin-top: 5px;
}

.file-present .text-container .validation-status-container .spinner-status,
.file-present .text-container .validation-status-container .close-status,
.file-present .text-container .validation-status-container .done-status {
    font-size: 12px;
    font-weight: 600;
}

.file-present .text-container .validation-status-container .status-text{
    font-size: 13px;
    color: #4B5563;
    font-weight: 400;
    margin-left: 5px;
}

.file-present .text-container .validation-status-container .close-status {
    color: #B91C1C;
}

.file-present .text-container .validation-status-container .done-status {
    color: #37c47b;
}

.file-present .buttons .close-icon {
    height: 14px;
    width: 14px;
}
