.exam-error-modal-row {
    height: 30px;
}

.exam-error-modal-row .index,
.exam-error-modal-row .value {
    font-size: 14px;
    font-weight: 500;
    color: #1F2937;
}

.exam-error-modal-row .value {
    margin-left: 5px;
}
