.add-category-container {}

.add-category-container .add-category-form .add-button-container {
    margin-top: 15px;
}

.add-category-container .add-category-form .field {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
}

.add-category-container .add-category-form .no-margin-top {
    margin-top: 0;
}

.add-category-container .add-category-form .field .label,
.add-category-container .add-category-form .field .sub-label{
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
}

.add-category-container .add-category-form .field .error-label {
    font-size: 12px;
}

.add-category-container .add-category-form .field .input,
.add-category-container .add-category-form .field .p-multiselect,
.add-category-container .add-category-form .field .p-inputnumber {
    margin-top: 7.5px;
    width: 100%;
    height: 44px;
    font-size: 14px;
}

.add-category-container .add-category-form .field .p-inputnumber input {
    font-size: 14px;
}

.add-category-container .add-category-form .field .input .p-dropdown-label {
    font-size: 14px;
}
